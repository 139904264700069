<template>
    <div class="right_con">
        <div class="article animated bounceInRight" v-for="(value,index) in contents">
            <div class="back-img" :style="{backgroundImage: 'url('+value.blogPhoto+')'}">
                <div class="mask"></div>
                <a  href="javascript:void(0)" @click="JumpBolg(value.id,value.blogTitle)"><span>{{value.labelName}}</span> {{value.blogTitle}}</a>
                <p>{{value.blogViceTitle}}</p>
            </div>
            <div class="titles">
                <!-- 时间 -->
                <a href="javascript:void(0)" >
                    <svg class="icon" aria-hidden="true" >
                        <use xlink:href="#icon-rili"></use>
                    </svg>
                    <span>{{value.releaseDt}}</span>
                </a>
                <!-- 评论-->
                <a href="javascript:void(0)">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-pinglun3"></use>
                    </svg>
                    <span>{{value.comment}}条评论</span>
                </a>
                <!-- 浏览量 -->
                <a href="javascript:void(0)" >
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-liulan"></use>
                    </svg>
                    <span>{{value.views}}人浏览</span>
                </a>
                <!-- 点赞 -->
                <a href="javascript:void(0)" @click="addlike(value.id)">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-dianzan"></use>
                    </svg>
                    <span>{{value.blogLikes}}人点赞</span>
                 </a>
                <!-- 阅读全文  -->
                <a href="javascript:void(0)" class="read" @click="JumpBolg(value.id)">
                    <span>阅读全文</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "defaultContent",
        data(){
            return{
                contents:[],
                params:{
                    /* 每页显示行数 */
                    pagesize:5,
                    /* 当前页 */
                    pagenum:1,
                    /* 当前选中的标签 */
                    labelName:'',
                },
            }
        },
        methods:{
            /* 跳转文章页 */
            async JumpBolg(value,name){
                /* 保存要使用的单页title */
                this.$store.commit("updatetitleName",name)
                /* 隐藏轮播图和左侧 */
                this.$store.commit("updateRotation",false)
                this.$store.commit("updateLeftShow",true)
                await this.$home.addBolgviews({id:value})
                /* 路由跳转 */
                this.$router.push({path:"/blogpostImg/"+value})
            },
            /* 获取文章列表 */
            async blogList(){
                const res = await this.$home.blogList(this.params)
                this.contents = res.data.list
                /* 总行数 */
                this.total = res.data.total
            },
            /* 点赞 */
            async addlike(value){
                await this.$home.addlikes({id:value})
                this.blogList()
            },
        },
        created() {
            this.blogList()
            /* 显示轮播图 */
            this.$store.commit("updateRotation",true)
            /* 显示左侧*/
            this.$store.commit("updateLeftShow",false)
        }
    }
</script>

<style scoped>

</style>
